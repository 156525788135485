import React from "react";

const Loader = () => {
    return (
        <div
            style={{
                width: "100vw",
                height: "100vh",
                position: "fixed",
                zIndex: 11,
                left: 0,
                top: 0,
                background: "rgba(255,255,255,0.7)",
            }}
        >
            <div className="loader-container">
                <div className="loader">
                    <div className="loader-logo"></div>
                    <div className="loader-logo"></div>
                    <div className="loader-logo"></div>
                    <div className="loader-logo"></div>
                    <div className="loader-logo"></div>
                </div>
            </div>
        </div>
    );
};

export default Loader;
